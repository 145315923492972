const openMobileMenu = (): void => {
  const $mobileMenu = document.querySelector<HTMLDivElement>("[data-mobile-menu]");
  if ($mobileMenu) {
    $mobileMenu.classList.remove("right-[-240px]")
    $mobileMenu.classList.add("right-0")
  }
}

const closeMobileMenu = (): void => {
  const $mobileMenu = document.querySelector<HTMLDivElement>("[data-mobile-menu]");
  if ($mobileMenu) {
    $mobileMenu.classList.add("right-[-240px]")
    $mobileMenu.classList.remove("right-0")
  }
}

const blurSiteContent = ():void => {
  const $siteContent = document.querySelector<HTMLDivElement>("[data-site-content]");
  if ($siteContent) {
    $siteContent.classList.add("blur-sm")
    $siteContent.classList.remove("blur-none")
  }
}

const unBlurSiteContent = ():void => {
  const $siteContent = document.querySelector<HTMLDivElement>("[data-site-content]");
  if ($siteContent) {
    $siteContent.classList.remove("blur-sm")
    $siteContent.classList.add("blur-none")
  }
}

const handleMobileMenuOpeners = (): void => {
  openMobileMenu();
  blurSiteContent();
}

const handleMobileMenuClosers = (): void => {
  closeMobileMenu();
  unBlurSiteContent();
}

export function initMobileMenu(): void {
  const $menuOpeners = document.querySelectorAll<HTMLButtonElement>("[data-mobile-menu-opener]");
  $menuOpeners.forEach($singleButton => {
    $singleButton.disabled = false;
    $singleButton.addEventListener("click", handleMobileMenuOpeners)
  });
  
  const $menuClosers = document.querySelectorAll<HTMLButtonElement>("[data-mobile-menu-closer]");
  $menuClosers.forEach($singleButton => {
    $singleButton.disabled = false;
    $singleButton.addEventListener("click", handleMobileMenuClosers)
  });
}
