interface productBundlesInterface {
  ilosc_produktow: number;
  wartosc_produktow: number;
  freeship: boolean;
  discount: number;
  price_per_unit: number;
  total_with_discount: number;
  total_without_discount: number;
  currency_symbol: string;
}

declare let PRODUCT_BUNDLES: productBundlesInterface[];

function formatNumber(num: number | string, fractionDigits: number, language = "pl-PL"): string {
  return parseFloat(num.toString()).toLocaleString(language, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}

const getBundleInfo = (quantity) => {
  return PRODUCT_BUNDLES.find((item) => item.ilosc_produktow === quantity);
};

const setupGUIBoundleInfo = (quantity) => {
  const selectedBoundle = getBundleInfo(quantity);
  console.log(selectedBoundle);

  if (selectedBoundle) {
    const $currentTotalPriceElement = document.querySelector<HTMLSpanElement>("[data-current-price]");
    if ($currentTotalPriceElement) {
      $currentTotalPriceElement.innerHTML = `${formatNumber(selectedBoundle.wartosc_produktow, 2)}&nbsp${
        selectedBoundle.currency_symbol
      }`;
    }

    const $regularTotalPriceElement = document.querySelector<HTMLSpanElement>("[data-by-old-price]");
    if ($regularTotalPriceElement) {
      const price = selectedBoundle.total_with_discount !== selectedBoundle.total_without_discount ? `${formatNumber(selectedBoundle.total_without_discount, 2)}&nbsp${
        selectedBoundle.currency_symbol
      }` : '';
      $regularTotalPriceElement.innerHTML = price;
    }

        const $unitPriceElement = document.querySelector<HTMLSpanElement>("[data-single-price]");
    if($unitPriceElement){
      $unitPriceElement.innerHTML = `${formatNumber(selectedBoundle.price_per_unit, 2)}&nbsp${selectedBoundle.currency_symbol}`
    }
  }
};

const setupQuantityInput = (quantity) => {
  const $quantityInputElement = document.querySelector<HTMLInputElement>("[data-quantity-input-field]");
  if ($quantityInputElement) {
    $quantityInputElement.value = quantity;
  }
};

const selectQuantityImage = (quantityID) => {
  const $allGalleryImages = document.querySelectorAll<HTMLElement>("[data-image-for]");
  $allGalleryImages.forEach(($singleElement) => {
    const elementQuantityID = $singleElement.getAttribute("data-image-for");
    $singleElement.hidden = elementQuantityID !== quantityID;
  });
};

const handleQuantityButtonAction = (event) => {
  const $clickedButton = event.currentTarget;
  if ($clickedButton) {
    const selectedImageQuantity = $clickedButton.getAttribute("data-item-quantity");
    selectedImageQuantity && selectQuantityImage(selectedImageQuantity);
    selectedImageQuantity && setupQuantityInput(selectedImageQuantity);
    selectedImageQuantity && setupGUIBoundleInfo(selectedImageQuantity);
  }
};

const initButtons = (): void => {
  const $quantityButtons = document.querySelectorAll<HTMLButtonElement>("[data-item-quantity]");
  $quantityButtons.forEach(($singleButton) => {
    $singleButton.disabled = false;
    $singleButton.addEventListener("click", handleQuantityButtonAction);
  });
};

export function initQuantityButtons() {
  initButtons();
}
