const setColorVariantSelection = (variantProductName) => {
  const $colorVariantSelector = document.querySelector<HTMLSelectElement>("select#kolor");
  if ($colorVariantSelector) {
    $colorVariantSelector.value = variantProductName;
  }
};

const setPucharseButtonID = (variantID) => {
  const $pucharseButton = document.querySelector<HTMLButtonElement>("button[name='add-to-cart']");
  if ($pucharseButton) {
    $pucharseButton.value = variantID;
  }
};

const hideAllPreviewSelections = () => {
  const $previews = document.querySelectorAll<HTMLSpanElement>("[data-color-preview]");
  $previews.forEach(($singlePreview) => {
    $singlePreview.classList.remove("is-selected");
  });
};

const handleVariationSelection = (event) => {
  const $clickedElement = event.currentTarget;

  const $variantPreview = $clickedElement.querySelector("[data-color-preview]");
  if ($variantPreview) {
    hideAllPreviewSelections();
    $variantPreview.classList.add("is-selected");
  }

  const variantProductID = $clickedElement.getAttribute("data-variant-id");
  const variantProductName = $clickedElement.getAttribute("data-variant-name");
  variantProductID && setPucharseButtonID(variantProductID);
  variantProductName && setColorVariantSelection(variantProductName);
  // TODO: Switch image in preview
};

export function initVariantsButtons() {
  const $variantButtons = document.querySelectorAll<HTMLButtonElement>("[data-variant-id]");
  $variantButtons.forEach(($singleButton) => {
    $singleButton.disabled = false;
    $singleButton.addEventListener("click", handleVariationSelection);
  });
}
