import { throttle } from "../utils/throttle";

const transformToScrolledMenu = () => {
  const $menu = document.querySelector("[data-medium-header]");
  if ($menu) {
    $menu.classList.add("scrolled-menu");
    $menu.classList.remove("stacked-menu");
  }

  const $perks = document.querySelector("[data-scrolled-hidden]");
  if ($perks) {
    $perks.classList.add("scrolled-perks");
    $perks.classList.remove("stacked-perks");
  }
};

const transformToStackedMenu = () => {
  const $menu = document.querySelector("[data-medium-header]");
  if ($menu) {
    $menu.classList.add("stacked-menu");
    $menu.classList.remove("scrolled-menu");
  }

  const $perks = document.querySelector("[data-scrolled-hidden]");
  if ($perks) {
    $perks.classList.add("stacked-perks");
    $perks.classList.remove("scrolled-perks");
  }
};

const scrollWatcher = (event) => {
  window.scrollY > 200 ? transformToScrolledMenu() : transformToStackedMenu();
};

export function initMediumMenu() {
  const $menu = document.querySelector("[data-medium-header]");
  if ($menu) {
    scrollWatcher();
    window.addEventListener("scroll", throttle(scrollWatcher, 500, true));
  }
}
